<script setup>
import { onBeforeMount, ref, computed } from 'vue'

//import defineModel
//const inputValue = defineModel();

const props = defineProps({
    modelValue: {
        type: [ String, Number ],
        default: ''
    },
    outlined: {
        type: Boolean,
        default: false
    },
    icon: {
        type: Object,
        default: null
    },
    iconPosition: {
        type: String,
        default: 'start'
    },
    inputMask: {
        type: [ String, Object ],
        default: null
    },
    money: {
        type: Boolean,
        deafult: false
    }
})

const mode = inject('mode', ref('light'))
const emit = defineEmits([ 'update:modelValue', 'clicked' ])
const inputValue = computed({
    get() {
        return props.modelValue
    },

    set(value) {
        emit('update:modelValue', value)
    }
})

const config = {
    masked: false,
    prefix: '',
    suffix: '',
    thousands: '.',
    decimal: ',',
    precision: 2,
    disableNegative: true,
    disabled: false,
    min: null,
    max: null,
    allowBlank: false,
    minimumNumberOfCharacters: 0,
    modelModifiers: {
        number: true,
    }
}
//mode
//const extraInputStyle = ref('')
const extraInputStyle = computed(() => {
    let classes = (props?.icon) ?
        (props.iconPosition == 'start') ?
            'tw-rounded-tl-none tw-rounded-bl-none' 
            : 'tw-rounded-tr-none tw-rounded-br-none'
        : ''
    
    if (props.outlined) {
        classes += ' tw-border-[1px] tw-border-slate-200 focus:tw-border-blue-wc'
    } else {
        classes += ' tw-border-[2px] tw-border-transparent focus:tw-border-b-primary'
    }

    if (mode.value == 'dark') {
        classes += ' tw-bg-darkmode tw-text-slate-200'
    } else {
        classes += ' tw-bg-slate-100'
    }

    return classes
})
onBeforeMount(() => {
    /*extraInputStyle.value = (props?.icon) ?
        (props.iconPosition == 'start') ?
            'tw-rounded-tl-none tw-rounded-bl-none' 
            : 'tw-rounded-tr-none tw-rounded-br-none'
        : ''
    
    if (props.outlined) {
        extraInputStyle.value += ' tw-border-[1px] tw-border-slate-200 focus:tw-border-blue-wc'
    } else {
        extraInputStyle.value += ' tw-border-[2px] tw-border-transparent focus:tw-border-b-primary'
    }*/
})
</script>

<script>
import { defineComponent } from 'vue'
import { mask } from 'vue-the-mask'

export default defineComponent({
    directives: {
        mask
    }
})
</script>

<template>
    <div class="tw-flex tw-w-full tw-flex-col !tw-bg-transparent">
        <div class="tw-flex tw-flex-1">
            <span
                v-if="icon && iconPosition == 'start'" 
                class="tw-inline-flex tw-px-3 tw-bg-slate-100 tw-rounded-tl-md tw-rounded-bl-md"
                :class="(outlined) ? 'tw-border-[1px] tw-border-slate-200 focus:tw-border-blue-wc tw-border-r-0' : ''"
            >
                <component
                    :is="icon"
                    class="tw-w-4 tw-text-default"
                />
            </span>


            <money3 
                v-if="money"
                v-model="inputValue"
                v-bind="config"

                class="tw-inline-flex tw-w-full tw-p-3 tw-outline-none tw-rounded-md 
                    tw-text-[16px] tw-text-default"
                :class="extraInputStyle"
                @click="$emit('clicked')"
                autocomplete="off"
            />
            
            <div
                class="tw-w-full"
                v-else
            >
                <input 
                    v-if="inputMask"
                    v-bind="$attrs"
                    v-model="inputValue"
                    class="tw-inline-flex tw-w-full tw-p-3 tw-outline-none tw-rounded-md 
                    tw-text-[16px] tw-text-default"
                    :class="extraInputStyle"
                    v-mask="inputMask"
                    @click="$emit('clicked')"
                    autocomplete="off"
                >

                <input 
                    v-else
                    v-bind="$attrs"
                    v-model="inputValue"
                    class="tw-block tw-w-full tw-p-3 tw-outline-none tw-rounded-md 
                    tw-text-[16px] tw-text-default"
                    :class="extraInputStyle"
                    @click="$emit('clicked')"
                    autocomplete="off"
                >
            </div>

            <span
                v-if="icon && iconPosition == 'end'" 
                class="tw-inline-flex tw-px-3 tw-bg-slate-200 tw-rounded-tr-md tw-rounded-br-md"
                :class="(outlined) ? 'tw-border-[1px] tw-border-slate-200 focus:tw-border-blue-wc tw-border-l-0' : ''"
            >
                <component
                    :is="icon"
                    class="tw-w-4 tw-text-default"
                />
            </span>
        </div>
    </div>
</template>